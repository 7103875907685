import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/Seo';
import styles from './verification/index.module.scss';

function NotFoundPage() {
  const browser = typeof window !== 'undefined' && window;

  useEffect(() => {
    const statusCode =
      window.location.pathname.includes('/404') ||
      window.location.pathname.includes('/403')
        ? 404
        : 200;

    if (statusCode === 404) {
      navigate('/404');
    }
  }, []);

  return (
    browser && (
      <Layout mainClasses={styles.block404}>
        <>
          <SEO title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </>
      </Layout>
    )
  );
}

export default NotFoundPage;
